module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(excluded-link|external)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cerezo Rosa","short_name":"cerezorosa","start_url":"/","background_color":"#1c1c1c","theme_color":"#ffb2c2","display":"standalone","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ced1fb817b1f0c178781146a5db77378"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
